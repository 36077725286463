import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Link,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip
} from "@nextui-org/react";

import TenantsViewModel from '../../viewModels/TenantsViewModel';
import { EditIcon } from '../../assets/icons/EditIcon';
import { TenantWithConfigurations } from '../../viewModels/TenantsViewModel';

type ColumnKey = keyof TenantWithConfigurations | 'actions';

type Column = {
  key: ColumnKey & React.Key;
  label: string;
  width?: number | null;
}

// Type-safe columns definition
const columns: Column[] = [
  {
    key: "name",
    label: "Name",
    width: null,
  },
  {
    key: "actions",
    label: "Actions",
    width: 100,
  }
];

const TenantsPage = observer(() => {
  const [viewModel] = useState(() => new TenantsViewModel());

  const renderCell = useCallback((tenant: TenantWithConfigurations, columnKey: ColumnKey & React.Key) => {
    switch (columnKey) {
      case "name":
        return <p>{tenant.name}</p>;
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Tooltip content="Edit">
              <Link className="text-default-400" color="foreground" href={`/admin/tenants/${tenant.id}`}>
                <EditIcon />
              </Link>
            </Tooltip>
          </div>
        );
      default:
        return null;
    }
  }, []);

  const { tenants } = viewModel;

  if (viewModel.error) {
    return (
      <div className='grid place-items-center place-content-center'>
        <p className='text-danger'>{viewModel.error}</p>
      </div>
    );
  }

  return (
    <div className='flex-1 p-8 max-w-[800px] mx-auto'>
      <div className='flex flex-col h-full'>
        <h1 className='text-2xl font-bold'>Tenants</h1>
        <Table
          aria-label="Tenants table"
          isStriped
          isHeaderSticky
          className="h-full"
        >
          <TableHeader columns={columns}>
            {(column) => (
              <TableColumn key={column.key} width={column.width}>
                {column.label}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody
            loadingContent={<Spinner />}
            isLoading={viewModel.isLoading}
            items={tenants.slice().sort((a: TenantWithConfigurations, b: TenantWithConfigurations) => a.name.localeCompare(b.name))}
          >
            {(item: TenantWithConfigurations) => (
              <TableRow key={item.id}>
                {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div >
  )
});

export default TenantsPage;