import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import {
  Button,
  Input,
  Select,
  SelectItem,
  Spinner,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Textarea,
  Tooltip
} from "@nextui-org/react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "@nextui-org/react";

import TenantViewModel, { TenantUpdate, ConfigurationType } from '../../viewModels/TenantViewModel';
import { EditIcon } from '../../assets/icons/EditIcon';
import toast, { Toaster } from 'react-hot-toast';
import { TenantWithConfigurations, TenantConfiguration } from '../../viewModels/TenantViewModel';
import { DeleteIcon } from '../../assets/icons/DeleteIcon';
import { getTypedFormData } from '../../utils/TypeFormData';

type ColumnKey = keyof TenantConfiguration | 'actions';

type Column = {
  key: ColumnKey & React.Key;
  label: string;
  width?: number | null;
}

// Type-safe columns definition
const columns: Column[] = [
  {
    key: "config_type",
    label: "Type",
    width: 200,
  },
  {
    key: "config_value",
    label: "Value",
    width: null,
  },
  {
    key: "actions",
    label: "Actions",
    width: 100,
  }
];

type FormData = {
  config_type?: string;
  config_value?: string;
}

const TenantPage = observer(() => {
  const { id } = useParams();
  const [viewModel] = useState(() => new TenantViewModel(Number(id)));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedConfiguration, setSelectedConfiguration] = useState<TenantConfiguration | null>(null);

  const handleSubmitConfiguration = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!tenant) return;
    event.preventDefault();

    const formData = getTypedFormData<FormData>(event.currentTarget);

    const params = {
      config_type: formData.get('config_type') as ConfigurationType,
      config_value: {
        value: formData.get('config_value') || '',
      },
    }

    try {
      if (selectedConfiguration?.id) {
        await viewModel.updateConfiguration(selectedConfiguration.id, params);
      } else {
        await viewModel.createConfiguration(params);
      }
      setIsModalOpen(false);
      toast.success('Configuration updated successfully');
    } catch (error) {
      toast.error('Failed to update configuration');
    }
  };

  const handleDeleteConfiguration = async (id: number) => {
    try {
      await viewModel.deleteConfiguration(id);
      toast.success('Configuration deleted successfully');
    } catch (error) {
      toast.error('Failed to delete configuration');
    }
  }

  const { tenant } = viewModel;

  const renderCell = (configuration: TenantConfiguration, columnKey: React.Key) => {

    switch (columnKey) {
      case "config_type":
        return (
          <div className="flex flex-col">
            <span className="text-tiny text-default-500">{configuration.config_type}</span>
          </div>
        );
      case "config_value":
        return (
          <div className="m-w-full">
            <p className="text-sm line-clamp-2">{configuration.config_value.value}</p>
          </div>
        );
      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Tooltip content="Edit">
              <span onClick={() => {
                setSelectedConfiguration(configuration);
                setIsModalOpen(true);
              }} className="text-lg text-default-400 cursor-pointer active:opacity-50">
                <EditIcon />
              </span>
            </Tooltip>
            <Tooltip color="danger" content="Delete">
              <span onClick={() => {
                if (configuration.id) {
                  handleDeleteConfiguration(configuration.id)
                }
              }} className="text-lg text-danger cursor-pointer active:opacity-50">
                <DeleteIcon />
              </span>
            </Tooltip>
          </div>
        );
      default:
        return 'NA';
    }
  }

  const topContent = React.useMemo(() => {
    return (
      <Button
        className='w-fit'
        color="primary"
        onClick={() => {
          setSelectedConfiguration(null);
          setIsModalOpen(true);
        }}
      >
        Add Configuration
      </Button>
    )
  }, [])

  if (viewModel.error) {
    return (
      <div className='grid place-items-center place-content-center'>
        <p className='text-danger'>{viewModel.error}</p>
      </div>
    );
  }

  return (
    <>
      <Toaster />
      <Modal
        isOpen={isModalOpen}
        onOpenChange={() => setIsModalOpen(false)}
        placement="top-center"
      >
        <ModalContent>
          {(onClose) => (
            <form onSubmit={handleSubmitConfiguration}>
              <ModalHeader className="flex flex-col gap-1">Tenant Configuration</ModalHeader>
              <ModalBody>
                <Select
                  label="Type"
                  id="config_type"
                  name="config_type"
                  key="config_type"
                  defaultSelectedKeys={selectedConfiguration?.config_type ? [selectedConfiguration.config_type] : []}
                  isRequired
                >
                  {viewModel.availableConfigurations.map((configuration: ConfigurationType) => {
                    return (
                      <SelectItem key={configuration} value={configuration}>{configuration}</SelectItem>
                    )
                  })}
                </Select>
                <Textarea
                  id="config_value"
                  name="config_value"
                  key="config_value"
                  label="Value"
                  type="text"
                  defaultValue={selectedConfiguration?.config_value.value}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" type="submit">
                  Save
                </Button>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
      <div className='flex-1 p-4 overflow-y-auto'>
        <div className='grid grid-rows-2'>
          <div className='row-span-1 h-[400px]'>
            <div className='grid grid-cols-3 gap-3 h-[400px]'>
              <div className='col-span-1 flex flex-col gap-2 h-[400px]'>
                <h1 className='text-2xl font-bold'>Basic Information</h1>
                <Input
                  value={tenant?.name || ''}
                  disabled
                  id="name"
                  label="Name"
                  type="text"
                />
              </div>
              <div className='col-span-2 flex flex-col gap-2 h-[400px]'>
                <h1 className='text-2xl font-bold'>Configurations</h1>
                <Table
                  aria-label="Example table with dynamic content"
                  className="h-full"
                  isHeaderSticky
                  layout="fixed"
                  topContent={topContent}
                >
                  <TableHeader columns={columns}>
                    {(column) => <TableColumn key={column.key} width={column.width}>{column.label}</TableColumn>}
                  </TableHeader>
                  <TableBody items={tenant?.configurations || []}>
                    {(item) => (
                      <TableRow key={item.id}>
                        {(columnKey) => (
                          <TableCell>
                            {renderCell(item, columnKey)}
                          </TableCell>
                        )}
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {/* {tenant?.configurations.map((configuration: TenantConfiguration) => {
                  return (
                    <div key={configuration.id} className='flex flex-col gap-2'>
                      <Textarea
                        defaultValue={configuration.config_value.value}
                        id={configuration.config_type}
                        label={configuration.config_type}
                        type="text"
                      />
                    </div>
                  )
                })}
                <Button color="primary">Add Configuration</Button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
});

export default TenantPage;