import { action, makeObservable, observable, runInAction } from 'mobx';
import BaseViewModel from './BaseViewModel';

export enum ConfigurationType {
  PERSONALIZATION_PROMPT = "personalization_prompt"
}

export interface TenantConfiguration {
  id?: number;
  tenant_id: number;
  config_type: ConfigurationType;
  config_value: {
    value: string;
  };
  removed: boolean;
}

export interface TenantWithConfigurations {
  id: number;
  name: string;
  configurations: TenantConfiguration[];
}

export interface TenantUpdate {
  id: number;
  configurations: {
    config_type: ConfigurationType;
    config_value: {
      value: string;
    };
    removed: boolean;
  }[];
}

export default class TenantViewModel extends BaseViewModel {
  tenant: TenantWithConfigurations | null = null;
  availableConfigurations: ConfigurationType[] = [ConfigurationType.PERSONALIZATION_PROMPT];
  tenantId: number | null = null;

  constructor(tenantId: number) {
    super();
    makeObservable(this, {
      fetchTenant: action,
      createConfiguration: action,
      updateConfiguration: action,
      deleteConfiguration: action,
      availableConfigurations: observable,
      tenant: observable
    });
    this.tenantId = tenantId;
    this.fetchTenant();
  }

  async fetchTenant() {
    try {
      const response = await this.api.get<TenantWithConfigurations>(`/admin/tenants/${this.tenantId}`)
      runInAction(() => {
        this.tenant = response.data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to fetch tenants';
        this.isLoading = false;
      });
    }
  }

  async createConfiguration(configuration: {
    config_type: ConfigurationType;
    config_value: { value: string };
  }) {
    try {
      const response = await this.api.post<TenantConfiguration>(
        `/admin/tenants/${this.tenantId}/configurations`,
        configuration
      );
      runInAction(() => {
        if (this.tenant) {
          this.tenant.configurations.push(response.data);
        }
      });
      return response.data;
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to create configuration';
      });
      throw error;
    }
  }

  async updateConfiguration(configId: number, configuration: {
    config_type?: ConfigurationType;
    config_value?: { value: string };
  }) {
    try {
      const response = await this.api.put<TenantConfiguration>(
        `/admin/tenants/${this.tenantId}/configurations/${configId}`,
        configuration
      );
      runInAction(() => {
        if (this.tenant) {
          const index = this.tenant.configurations.findIndex(c => c.id === configId);
          if (index !== -1) {
            this.tenant.configurations[index] = response.data;
          }
        }
      });
      return response.data;
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to update configuration';
      });
      throw error;
    }
  }

  async deleteConfiguration(configId: number) {
    try {
      await this.api.delete(
        `/admin/tenants/${this.tenantId}/configurations/${configId}`
      );
      runInAction(() => {
        if (this.tenant) {
          this.tenant.configurations = this.tenant.configurations.filter(
            c => c.id !== configId
          );
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to delete configuration';
      });
      throw error;
    }
  }
}
