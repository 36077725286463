import React from 'react';
import {
  Avatar,
  Button,
  DropdownItem,
  DropdownMenu,
  Dropdown,
  DropdownSection,
  DropdownTrigger,
  Link,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
} from "@nextui-org/react";
import { routes, Route } from '../routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import AuthStore from '../stores/AuthStore';
import UserProfileStore from '../stores/UserProfileStore';
import ThemeSwitcher from './ThemeSwitcher';
import { ChevronDown } from '../assets/icons/ChevronIcon';
import { UserRoleType } from '../models/UserSystemRole';

const ProfileActions = {
  profile: 'profile',
  tenant_settings: 'tenant_settings',
  logout: 'logout',
  admin_users: 'admin_users',
  admin_tenants: 'admin_tenants',
}

const NavigationBar: React.FC = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const logout = () => {
    AuthStore.clearToken();
  };

  const navItems = routes.filter(route => route.includeInNav);

  const renderItem = (item: Route) => {
    return (
      <NavbarItem key={item.path} isActive={location.pathname === item.path}>
        <Link
          href={item.path}
          color="foreground"
          aria-current={location.pathname === item.path ? 'page' : undefined}
        >
          {item.label}
        </Link>
      </NavbarItem>
    )
  }

  const dropDownItems = () => {
    const isAdmin = UserProfileStore.profile?.system_roles.some(role => role.user_role_type === UserRoleType.ADMIN);
    
    const sections = [
      <DropdownSection showDivider key="common">
        <DropdownItem textValue="Signed in as" key={ProfileActions.profile} className="h-14 gap-2">
          <p className="font-semibold">Signed in as</p>
          <p className="font-semibold">{UserProfileStore.profile?.email}</p>
        </DropdownItem>
        <DropdownItem textValue="Tenant Settings" href="/tenant_settings" key={ProfileActions.tenant_settings} description={UserProfileStore.profile?.active_tenant?.tenant.name}>Tenant Settings</DropdownItem>
        <DropdownItem textValue="Log Out" key={ProfileActions.logout} color="danger">
          Log Out
        </DropdownItem>
      </DropdownSection>
    ];

    if (isAdmin) {
      sections.push(
        <DropdownSection title="Admin actions" key="admin">
          <DropdownItem textValue="Users" href="/admin/users" key={ProfileActions.admin_users}>Users</DropdownItem>
          <DropdownItem textValue="Tenants" href="/admin/tenants" key={ProfileActions.admin_tenants}>Tenants</DropdownItem>
        </DropdownSection>
      );
    }

    return (
      <DropdownMenu 
        aria-label="Profile Actions" 
        variant="flat" 
        onAction={(key) => {
          switch (key) {
            case ProfileActions.profile:
              break;
            case ProfileActions.logout:
              logout();
              break;
          }
        }}
      >
        {sections}
      </DropdownMenu>
    );
  }

  return (
    <Navbar
      isBordered
      classNames={{
        item: [
          "flex",
          "relative",
          "h-full",
          "items-center",
          "data-[active=true]:after:absolute",
          "data-[active=true]:after:bottom-0",
          "data-[active=true]:after:left-0",
          "data-[active=true]:after:right-0",
          "data-[active=true]:after:h-[1px]",
          "data-[active=true]:after:rounded-[1px]",
          "data-[active=true]:after:bg-primary",
        ],
      }}>
      <NavbarBrand>
        <p>SegTool</p>
      </NavbarBrand>
      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        {navItems.map((item) => renderItem(item))}
      </NavbarContent>
      <NavbarContent as="div" justify="end">
        <Dropdown placement="bottom-end" >
          <DropdownTrigger>
            <Avatar
              isBordered
              as="button"
              className="transition-transform"
              color="secondary"
              name={UserProfileStore.profile?.email}
              size="sm"
            />
          </DropdownTrigger>
          {dropDownItems()}
        </Dropdown>
      </NavbarContent>
    </Navbar>
  );
});

export default NavigationBar;
