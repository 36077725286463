import { makeObservable, observable, action, runInAction } from 'mobx';
import BaseViewModel from './BaseViewModel';

export type ExportFormat = 'csv';

export default class ExportViewModel extends BaseViewModel {
  private segmentationId: number | null = null;
  format: ExportFormat = 'csv';
  isLoading = false;
  error: string | null = null;

  constructor() {
    super();
    makeObservable(this, {
      format: observable,
      setFormat: action,
      setSegmentationId: action,
      startExport: action
    });
  }

  setSegmentationId(id: number) {
    this.segmentationId = id;
  }

  setFormat(format: ExportFormat) {
    this.format = format;
  }

  private getFileExtension(): string {
    return 'csv';
    // return this.format === 'excel' ? 'xlsx' : 'csv';
  }

  async startExport(): Promise<void> {
    if (!this.segmentationId) return;

    runInAction(() => {
      this.isLoading = true;
      this.error = null;
    });

    try {
      const response = await this.api.get(`/segmentations/${this.segmentationId}/export`, {
        params: { format: this.format },
        responseType: 'blob'
      });

      // Create a download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `segmentation-${this.segmentationId}.${this.getFileExtension()}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      runInAction(() => {
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to export data';
        this.isLoading = false;
      });
    }
  }
} 