import { action, makeObservable, observable, runInAction } from 'mobx';
import BaseViewModel from './BaseViewModel';

export enum ConfigurationType {
  PERSONALIZATION_PROMPT = "personalization_prompt"
}

export interface TenantConfiguration {
  id: number;
  tenant_id: number;
  config_type: ConfigurationType;
  config_value: {
    value: string;
  };
  removed: boolean;
}

export interface TenantWithConfigurations {
  id: number;
  name: string;
  configurations: TenantConfiguration[];
}

export interface TenantUpdate {
  id: number;
  configurations: {
    config_type: ConfigurationType;
    config_value: {
      value: string;
    };
    removed: boolean;
  }[];
}

export default class TenantsViewModel extends BaseViewModel {
  tenants: TenantWithConfigurations[] = [];
  availableConfigurations: ConfigurationType[] = [ConfigurationType.PERSONALIZATION_PROMPT];

  constructor() {
    super();
    makeObservable(this, {
      fetchTenants: action,
      updateTenant: action,
      availableConfigurations: observable,
      tenants: observable
    });
    this.fetchTenants();
  }

  async fetchTenants() {
    try {
      const response = await this.api.get<TenantWithConfigurations[]>('/admin/tenants')
      runInAction(() => {
        this.tenants = response.data;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to fetch tenants';
        this.isLoading = false;
      });
    }
  }

  async updateTenant(update: TenantUpdate) {
    try {
      await this.api.patch(`/admin/tenants/${update.id}`, update);
      await this.fetchTenants();
    } catch (error) {
      throw new Error('Failed to update tenant');
    }
  }
}
